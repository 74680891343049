import React from "react"

import { useLanguage } from "@hooks/useLanguage"
import { useSanity } from "@hooks/useSanity"
import { Account } from "../../Account/Account"

export const withCustomerWishlist = Component => ({ name = `CustomerWishlist`, page, ...props }) => {
  const locales = useLanguage(`wishlist`)
  const { heroNormaliser } = useSanity()

  const content = {
    ...page,
    heroBanner: heroNormaliser(page),
  }

  Component.displayName = name
  return (
    <Account {...props} description={locales.description} full title={locales.title}>
      <Component {...content} />
    </Account>
  )
}
