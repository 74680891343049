import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { CustomerWishlist as Page } from "@components/Customer/Wishlist/CustomerWishlist"

export const query = graphql`
  query {
    page: sanitySavedPage {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      products: _rawNavigationProducts(resolveReferences: { maxDepth: 2 })
      inspiration: _rawNavigationInspiration(resolveReferences: { maxDepth: 2 })
    }
  }
`

export default withAuthentication(({ data, ...props }) => <Page {...props} {...data} />)
